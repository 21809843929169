import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FuseLoadingService } from "@fuse/services/loading";
import { environment } from "environments/environment";
import { SseClient } from 'ngx-sse-client';
import { BehaviorSubject, Observable, Subscription } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SseService {

    private _events: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _connectionState: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(
        private _sseClient: SseClient
    ) {

    }

    get events$(): Observable<any> {
        return this._events.asObservable();
    }

    get connectionState$(): Observable<any> {
        return this._connectionState.asObservable();
    }

    stream(channels: string[]): void {
        const authToken = environment.mercureToken;
        const url = environment.mercureUrl;
        const headers = new HttpHeaders().set('Authorization', `Basic ${authToken}`);

        let topics = '';
        channels.forEach((channel, index) => {
            topics += `${index === 0 ? '' : '&'}topic=${channel}`;
        });
        this._connectionState.next(true);
        const subscription = this._sseClient.stream(`${url}?${topics}`, { reconnectionDelay: 1000 }).subscribe(
            (event) => {
                if (event.type === 'error') {
                    const errorEvent = event as ErrorEvent;
                    this._connectionState.next(false);
                    console.error(errorEvent.error, errorEvent.message);
                } else {
                    const messageEvent = event as MessageEvent;
                    this._events.next(JSON.parse(messageEvent.data));
                }
            }
        );
        this.subscriptions.push(subscription);
    }

    unsubscribe(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this._connectionState.next(false);
        this.subscriptions = [];
    }

}