import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Router, UrlSerializer } from '@angular/router';
import { TenantDbConfig } from '../interfaces/tenantdbconfig';


@Injectable({
    providedIn: 'root'
})
export class RestApiClientService {

    baseUrl: string = environment.baseUrl;
    loginUrl: string = environment.loginUrl;
    ssoLoginUrl: string = environment.ssoLoginUrl;
    headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });



    constructor(
        private http: HttpClient,
        private router: Router,
        private serializer: UrlSerializer
    ) {

    }

    private getUser() {
        if (localStorage.getItem("user")) {
            return JSON.parse(localStorage.getItem("user"));
        }
        return null;
    }

    private getEnterprisesIds() {
        if (this.getUser() && this.getUser().user.userEnterprises.length) {
            const userEnterprises = this.getUser().user.userEnterprises;
            const ids = [];
            userEnterprises.forEach(userEnterprise => {
                ids.push(userEnterprise.enterprise.id);
            })

            return ids;
        }
        return [];
    }

    execute(action, data): Observable<any> {
        return this.http.post(this.baseUrl + action, data) //.catch(this.handleError)
    }

    export(action, data): Observable<any> {
        return this.http.post(this.baseUrl + action, data, { responseType: 'blob' }) //.catch(this.handleError)
    }

    getFile(action, params): Observable<any> {
        return this.http.get(this.baseUrl + action, { params, responseType: 'blob' }) //.catch(this.handleError)
    }

    request(url, data): Observable<any> {
        return this.http.post(url, data) //.catch(this.handleError)
    }

    get<T=any>(action: string, params?: any ): Observable<T> {
        return this.http.get<T>(this.baseUrl + action, {params}) //.catch(this.handleError)
    }

    post<T=any>(action, data, params?: any): Observable<T> {
        return this.http.post<T>(this.baseUrl + action, data, {params}) //.catch(this.handleError)
    }

    put<T=any>(action, id, data, params?: any): Observable<T> {
        return this.http.put<T>(this.baseUrl + action + '/' + id, data, { headers: this.headers, params }) //.catch(this.handleError)
    }

    patch(action, id, data): Observable<any> {
        return this.http.patch(this.baseUrl + action + '/' + id, data, { headers: this.headers }) //.catch(this.handleError)
    }

    delete(action, id): Observable<any> {
        return this.http.delete(this.baseUrl + action + '/' + id, { headers: this.headers }) //.catch(this.handleError)
    }

    login(user: { username: string, password: string }) {
        return this.http.post(this.loginUrl, user)
    }

    ssoLogin(user: { username: string }) {
        return this.http.post(this.ssoLoginUrl, user)
    }

    refreshToken(data: { refresh_token: string }) {
        return this.post('token/refresh', data);
    }

    getCurrentUser() {
        return this.get('current_user');
    }
    

    /* Enterprises */
    getEnterprises(params?) {
        params = params ? params : {};
        if (!params['id'] && !params['id[]']) {
            params['id[]'] = this.getEnterprisesIds();
        }
        return this.get('enterprises', params);
    }

    getAllEnterprises(params?) {
        return this.get('enterprises', params);
    }

    createEnterprise(data) {
        return this.post('enterprises', data);
    }

    updateEnterprise(data) {
        return this.put('enterprises', data.id, data);
    }

    deleteEnterprise(data) {
        return this.delete('enterprises', data.id);
    }

    /* Countries */
    getCountries(params?) {
        return this.get('countries', params);
    }

    /* Currencies */
    getCurrencies(params?) {
        return this.get('currencies', params);
    }

    createCurrency(data) {
        return this.post('currencies', data);
    }

    updateCurrency(data) {
        return this.put('currencies', data.id, data);
    }

    deleteCurrency(data) {
        return this.delete('currencies', data.id);
    }

    /* Clients */
    getClients(params?) {
        return this.get('clients', params);
    }

    getClientsWithInvoices(params?) {
        return this.get('clients/with_invoices', params);
    }

    createClient(data) {
        return this.post('clients', data);
    }

    updateClient(data) {
        return this.put('clients', data.id, data);
    }

    deleteClient(data) {
        return this.delete('clients', data.id);
    }

    /* Offer statuses */
    getOfferStatuses(params?) {
        return this.get('offer_statuses', params);
    }

    createOfferStatus(data) {
        return this.post('offer_statuses', data);
    }

    updateOfferStatus(data) {
        return this.put('offer_statuses', data.id, data);
    }

    deleteOfferStatus(data) {
        return this.delete('offer_statuses', data.id);
    }

    /* Offer types */
    getOfferTypes(params?) {
        return this.get('offer_types', params);
    }

    createOfferType(data) {
        return this.post('offer_types', data);
    }

    updateOfferType(data) {
        return this.put('offer_types', data.id, data);
    }

    deleteOfferType(data) {
        return this.delete('offer_types', data.id);
    }

    /* TVA */
    getTva(params?) {
        return this.get('tvas', params);
    }

    createTva(data) {
        return this.post('tvas', data);
    }

    updateTva(data) {
        return this.put('tvas', data.id, data);
    }

    deleteTva(data) {
        return this.delete('tvas', data.id);
    }

    /* Suppliers */
    getSuppliers(params?) {
        return this.get('suppliers', params);
    }

    getSuppliersWithInvoice(params?) {
        return this.get('suppliers/with_invoices', params);
    }

    createSupplier(data) {
        return this.post('suppliers', data);
    }

    updateSupplier(data) {
        return this.put('suppliers', data.id, data);
    }

    deleteSupplier(data) {
        return this.delete('suppliers', data.id);
    }


    /* Offers */
    getOffers(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        return this.get('offers', params);
    }

    createOffer(data) {
        return this.post('offers', data);
    }

    updateOffer(data) {
        return this.put('offers', data.id, data);
    }

    deleteOffer(data) {
        return this.delete('offers', data.id);
    }

    /* OfferLogs */
    getOfferLogs(params?) {
        return this.get('offer_logs', params);
    }

    createOfferLog(data) {
        return this.post('offer_logs', data);
    }

    updateOfferLog(data) {
        return this.put('offer_logs', data.id, data);
    }

    deleteOfferLog(data) {
        return this.delete('offer_logs', data.id);
    }

    /* Offer Categories */
    getOfferCategories(params?) {
        return this.get('offer_categories', params);
    }

    createOfferCategory(data) {
        return this.post('offer_categories', data);
    }

    updateOfferCategory(data) {
        return this.put('offer_categories', data.id, data);
    }

    deleteOfferCategory(data) {
        return this.delete('offer_categories', data.id);
    }

    /* Purchase Categories */
    getPurchaseCategories(params?) {
        return this.get('purchase_categories', params);
    }

    createPurchaseCategory(data) {
        return this.post('purchase_categories', data);
    }

    updatePurchaseCategory(data) {
        return this.put('purchase_categories', data.id, data);
    }

    deletePurchaseCategory(data) {
        return this.delete('purchase_categories', data.id);
    }

    /* Support */
    getSupports(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        return this.get('supports', params);
    }

    createSupport(data) {
        return this.post('supports', data);
    }

    updateSupport(data) {
        return this.put('supports', data.id, data);
    }

    deleteSupport(data) {
        return this.delete('supports', data.id);
    }

    /* Support types */
    getSupportTypes(params?) {
        return this.get('support_types', params);
    }

    createSupportType(data) {
        return this.post('support_types', data);
    }

    updateSupportType(data) {
        return this.put('support_types', data.id, data);
    }

    deleteSupportType(data) {
        return this.delete('support_types', data.id);
    }

    /* Invoices */
    getInvoices(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        params['deleted'] = 0;
        return this.get('invoices', params);
    }

    createInvoice(data) {
        return this.post('invoices', data);
    }

    updateInvoice(data) {
        return this.put('invoices', data.id, data);
    }

    deleteInvoice(data) {
        return this.delete('invoices', data.id);
    }

    /* Users */
    getUsers(params?) {
        params = params ? params : [];
        params['and[isDeleted]'] = "0";
        return this.get('users', params);
    }

    createUser(data) {
        return this.post('user/register', data);
    }

    updateUser(data) {
        return this.put('users', data.id, data);
    }

    deleteUser(data) {
        return this.delete('users', data.id);
    }

    changeUserPassword(data) {
        return this.post('user/password-change', data);
    }

    /* Roles */
    getRoles(params?) {
        params = params ? params : [];
        return this.get('roles', params);
    }

    createRole(data) {
        return this.post('roles', data);
    }

    updateRole(data) {
        return this.put('roles', data.id, data);
    }

    deleteRole(data) {
        return this.delete('roles', data.id);
    }

    /* Profiles */
    getProfiles(params?) {
        params = params ? params : [];
        return this.get('profiles', params);
    }

    createProfile(data) {
        return this.post('profiles', data);
    }

    updateProfile(data) {
        return this.put('profiles', data.id, data);
    }

    deleteProfile(data) {
        return this.delete('profiles', data.id);
    }

    /* Department */
    getDepartments(params?) {
        return this.get('departments', params);
    }

    createDepartment(data) {
        return this.post('departments', data);
    }

    updateDepartment(data) {
        return this.put('departments', data.id, data);
    }

    deleteDepartment(data) {
        return this.delete('departments', data.id);
    }

    /* Purchases */
    getPurchases(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        params['deleted'] = 0;
        return this.get('purchases', params);
    }

    createPurchase(data) {
        return this.post('purchases', data);
    }

    updatePurchase(data) {
        return this.put('purchases', data.id, data);
    }

    deletePurchase(data) {
        return this.delete('purchases', data.id);
    }

    /* Purchases Items */
    getPurchaseItems(params?) {
        return this.get('purchase_items', params);
    }

    createPurchaseItem(data) {
        return this.post('purchase_items', data);
    }

    updatePurchaseItem(data) {
        return this.put('purchase_items', data.id, data);
    }

    deletePurchaseItem(data) {
        return this.delete('purchase_items', data.id);
    }

    /* Purchase Invoices*/
    getPurchaseInvoices(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        params['deleted'] = 0;
        return this.get('purchase_invoices', params);
    }

    createPurchaseInvoice(data) {
        return this.post('purchase_invoices', data);
    }

    updatePurchaseInvoice(data) {
        return this.put('purchase_invoices', data.id, data);
    }

    deletePurchaseInvoice(data) {
        return this.delete('purchase_invoices', data.id);
    }

    /* Banks */
    getBanks(params?) {
        return this.get('banks', params);
    }

    createBank(data) {
        return this.post('banks', data);
    }

    updateBank(data) {
        return this.put('banks', data.id, data);
    }

    deleteBank(data) {
        return this.delete('banks', data.id);
    }

    /* Accounts */
    getAccounts(params?) {
        params = params ? params : {};
        params['isDeleted'] = 0;
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        return this.get('accounts', params);
    }

    createAccount(data) {
        return this.post('accounts', data);
    }

    updateAccount(data) {
        return this.put('accounts', data.id, data);
    }

    deleteAccount(data) {
        return this.delete('accounts', data.id);
    }

    /* Operation nature */
    getOperationNatures(params?) {
        return this.get('operation_natures', params);
    }

    createOperationNature(data) {
        return this.post('operation_natures', data);
    }

    updateOperationNature(data) {
        return this.put('operation_natures', data.id, data);
    }

    deleteOperationNature(data) {
        return this.delete('operation_natures', data.id);
    }

    /* Disbursement categories */
    getDisbursementCategories(params?) {
        return this.get('disbursement_categories', params);
    }

    createDisbursementCategory(data) {
        return this.post('disbursement_categories', data);
    }

    updateDisbursementCategory(data) {
        return this.put('disbursement_categories', data.id, data);
    }

    deleteDisbursementCategory(data) {
        return this.delete('disbursement_categories', data.id);
    }

    /* Financial Years */
    getFinancialYears(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        return this.get('financial_years', params);
    }

    createFinancialYear(data) {
        return this.post('financial_years', data);
    }

    updateFinancialYear(data) {
        return this.put('financial_years', data.id, data);
    }

    deleteFinancialYear(data) {
        return this.delete('financial_years', data.id);
    }

    /* Operations Ebanking*/
    getOperationsEBanking(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        return this.get('operation_e_bankings', params);
    }

    createOperationEBanking(data) {
        return this.post('operation_e_bankings', data);
    }

    updateOperationEBanking(data) {
        return this.put('operation_e_bankings', data.id, data);
    }

    /* Operations */
    getOperations(params?) {
        params = params ? params : {};
        if (!params['enterprise.id'] && !params['enterprise.id[]']) {
            params['enterprise.id[]'] = this.getEnterprisesIds();
        }
        return this.get('operations', params);
    }

    createOperations(data) {
        return this.post('operations', data);
    }

    updateOperation(data) {
        return this.put('operations', data.id, data);
    }

    deleteOperation(data) {
        return this.delete('operations', data.id);
    }

    getProvision(params?) {
        return this.get('operation/provisions', params)
    }

    getAccountProvision(params?) {
        return this.get('operation/account-provisions', params)
    }

    /* Sold History */
    getSoldHistory(params?) {
        return this.get('accounts/sold_history', params);
    }

    /* Pricing Profiles */
    getPricingProfiles(params?) {
        return this.get('pricing_profiles', params);
    }

    createPricingProfile(data) {
        return this.post('pricing_profiles', data);
    }

    updatePricingProfile(data) {
        return this.put('pricing_profiles', data.id, data);
    }

    deletePricingProfile(data) {
        return this.delete('pricing_profiles', data.id);
    }

    /* Macro taches */
    getMacroTaches(params?) {
        return this.get('macro_taches', params);
    }

    createMacroTache(data) {
        return this.post('macro_taches', data);
    }

    updateMacroTache(data) {
        return this.put('macro_taches', data.id, data);
    }

    deleteMacroTache(data) {
        return this.delete('macro_taches', data.id);
    }

    /* Pricing Tache */
    getPricingTaches(params?) {
        return this.get('pricing_taches', params);
    }

    createPricingTache(data) {
        return this.post('pricing_taches', data);
    }

    updatePricingTache(data) {
        return this.put('pricing_taches', data.id, data);
    }

    deletePricingTache(data) {
        return this.delete('pricing_taches', data.id);
    }

    /* Pricing Details */
    getPricingDetails(params?) {
        return this.get('pricing_details', params);
    }

    createPricingDetail(data) {
        return this.post('pricing_details', data);
    }

    updatePricingDetail(data) {
        return this.put('pricing_details', data.id, data);
    }

    deletePricingDetail(data) {
        return this.delete('pricing_details', data.id);
    }

    /* Pricing */
    getPricings(params?) {
        return this.get('pricings', params);
    }

    createPricing(data) {
        return this.post('pricings', data);
    }

    updatePricing(data) {
        return this.put('pricings', data.id, data);
    }

    deletePricing(data) {
        return this.delete('pricings', data.id);
    }

    getProfilesByPricingId(params) {
        return this.get('pricing/getProfiles', params);
    }

    getProfilesTJMByPricingId(params) {
        return this.get('pricing/getProfilesTJM', params);
    }

    /* Pricing expenses */
    getPricingExpenses(params?) {
        return this.get('pricing_expenses', params);
    }

    createPricingExpenses(data) {
        return this.post('pricing_expenses', data);
    }

    updatePricingExpenses(data) {
        return this.put('pricing_expenses', data.id, data);
    }

    deletePricingExpenses(data) {
        return this.delete('pricing_expenses', data.id);
    }

    /* Pricing expertise level */
    getExpertiseLevels(params?) {
        return this.get('expertise_levels', params);
    }

    createExpertiseLevel(data) {
        return this.post('expertise_levels', data);
    }

    updateExpertiseLevel(data) {
        return this.put('expertise_levels', data.id, data);
    }

    deleteExpertiseLevel(data) {
        return this.delete('expertise_levels', data.id);
    }

    /* Pricing profiles forfait */
    getPricingRProfiles(params?) {
        return this.get('pricing_r_profiles', params);
    }

    createPricingRProfile(data) {
        return this.post('pricing_r_profiles', data);
    }

    updatePricingRProfile(data) {
        return this.put('pricing_r_profiles', data.id, data);
    }

    deletePricingRProfile(data) {
        return this.delete('pricing_r_profiles', data.id);
    }


    /* Expense lines */
    getExpenseLines(params?) {
        return this.get('expense_lines', params);
    }

    createExpenseLine(data) {
        return this.post('expense_lines', data);
    }

    updateExpenseLine(data) {
        return this.put('expense_lines', data.id, data);
    }

    deleteExpenseLine(data) {
        return this.delete('expense_lines', data.id);
    }

    /* Dashboard */
    getCRCCapex(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }
        if (!params['statuses[]']) {
            params['statuses[]'] = ['START', 'RECETTE', 'END', 'BLOCKED']
        }
        return this.get('dashboard/crc/capex', params);
    }

    getCRCOpex(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/crc/opex', params);
    }


    getCapexSynthese(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/analytics/capex/synthese', params);
    }


    getInOutCurve(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/analytics/in_out_curve', params);
    }


    getOffersGroupedByCategory(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/analytics/get_offers_grouped_by_category', params);
    }


    getInOutCategories(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/analytics/in_out_categories', params);
    }

    getAvsPipeline(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/analytics/get_avs_pipeline', params);
    }

    getAvsMetrics(params) {
        params = params ? params : {};
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        return this.get('dashboard/analytics/get_avs_metrics', params);
    }


    /* Export Excel */
    exportQuotation(id) {
        return this.get('export/excel/quotation/' + id);
    }

    exportCRC(params?) {
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }
        if (!params['statuses[]']) {
            params['statuses[]'] = ['START', 'RECETTE', 'END', 'BLOCKED']
        }

        const tree = this.router.createUrlTree([], { queryParams: params });
        return this.baseUrl + 'export/excel/crc?' + this.serializer.serialize(tree).split('?')[1];
    }

    getExportQuotationUrl(id) {
        return this.baseUrl + 'export/excel/quotation/' + id;
    }

    exportBankAccounts(params?) {
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        const tree = this.router.createUrlTree([], { queryParams: params });
        return this.baseUrl + 'export/excel/accounts?' + this.serializer.serialize(tree).split('?')[1];
    }


    exportSalesInvoices(params?) {
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        const tree = this.router.createUrlTree([], { queryParams: params });
        return this.baseUrl + 'export/excel/sales/invoices?' + this.serializer.serialize(tree).split('?')[1];
    }


    exportAccountingOperations(params?) {
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        const tree = this.router.createUrlTree([], { queryParams: params });
        return this.baseUrl + 'export/excel/accounting/operations?' + this.serializer.serialize(tree).split('?')[1];
    }

    exportSalesSupport(params?) {
        if (!params['enterprises[]']) {
            params['enterprises[]'] = this.getEnterprisesIds();
        }

        const tree = this.router.createUrlTree([], { queryParams: params });
        return this.baseUrl + 'export/excel/sales/supports?' + this.serializer.serialize(tree).split('?')[1];
    }

}
