import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FuseNavigationItem } from "@fuse/components/navigation";
import { DateTime } from "luxon";
import { Observable, switchMap } from "rxjs";
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(private http: HttpClient) { }

    generateUUID(): string {
        return uuidv4();
    };

    mimeToExtension: { [key: string]: string } = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        'application/vnd.ms-excel': 'xls',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    };

    getExtensionFromMimeType(mimeType: string): string | undefined {
        return this.mimeToExtension[mimeType] ?? '';
    }

    removeSpecialChars(str: string): string {
        const regex = /[^a-zA-Z0-9\. ]/g;
        return str.replace(regex, '-');
    }

    formatAmount(num: number): string {
        if (num >= 1e9) {
            return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'G';
        }
        if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return num.toString();
    }

    isSameDay(current: string, compare: string): boolean {
        return DateTime.fromISO(current).hasSame(DateTime.fromISO(compare), 'day');
    }

    getRelativeFormat(date: string): string {
        return DateTime.fromISO(date).toRelativeCalendar();
    }

    addTimeToDate(date: any, time: string): DateTime {
        if (!date) {
            return null;
        }
        if (!time) {
            return date;
        }
        date = DateTime.fromISO(date);
        let timeParts = time.split(':');
        let hours = parseInt(timeParts[0], 10);
        let minutes = parseInt(timeParts[1], 10);
        let seconds = timeParts.length > 2 ? parseInt(timeParts[2], 10) : 0;

        return date.set({ hours, minutes, seconds });
    }

    getTimeFromDate(date: any, withSeconds: boolean = false): string {
        if (!date) {
            date = DateTime.now();
        } else {
            date = DateTime.fromISO(date);
        }

        if (withSeconds) {
            return date.toFormat('HH:mm:ss');
        } else {
            return date.toFormat('HH:mm');
        }
    }

    filterItems(event: Event, source: any[], filteredResults: any[], filterField: string): void {
        const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
        filteredResults.length = 0;
        const tempResults = source.filter(item => item[filterField].toLowerCase().includes(filterValue));
        Array.prototype.push.apply(filteredResults, tempResults);
    }


    getRolesByLink(link: string, ROUTES: FuseNavigationItem[]): string[] | undefined {
        const route = ROUTES.find(route => link.includes(route.link));
        if (route) {
            if (route.roles) {
                return route.roles;
            }
        } else {
            for (const route of ROUTES) {
                if (route.children && route.children.some(child => child.link === link && child.roles)) {
                    return route.children.find(child => child.link === link)?.roles;
                }
            }
        }
        return undefined;
    }

    urlToBase64(url: string): Observable<string> {
        return this.http.get(url, { responseType: 'blob' }).pipe(
            switchMap(blob => this.blobToBase64(blob))
        );
    }

    private blobToBase64(blob: Blob): Observable<string> {
        return new Observable(observer => {
            const reader = new FileReader();
            reader.onloadend = () => {
                observer.next(reader.result as string);
                observer.complete();
            };
            reader.onerror = error => {
                observer.error(error);
            };
            reader.readAsDataURL(blob);
        });
    }



}