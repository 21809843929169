export const environment = {
    baseUrl: 'https://neons-backend.dev02.ovh.synelia.tech/api/',
    loginUrl: 'https://neons-backend.dev02.ovh.synelia.tech/auth',
    ssoLoginUrl: 'https://neons-backend.dev02.ovh.synelia.tech/auth/sso-login',
    backendUrl: 'https://neons-backend.dev02.ovh.synelia.tech',
    //mercureUrl: 'https://mercure.ajax.dev02.ovh.synelia.tech/.well-known/mercure',
    mercureUrl: 'https://academy.cofinaonline.com/.well-known/mercure',
    mercureToken: '',
    oidcClientId: '9j5T3sGSnlEkDj1ZIBU5dhY59MEa',
    oidcAuthority: 'https://sso.synelia.tech/oauth2/token',
};
