import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { UserService } from "../user/user.service";
import { FuseConfirmationService } from "@fuse/services/confirmation";

@Injectable({
    providedIn: 'root'
})
export class EventMessageManager {

    private _events: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(
        private _userService: UserService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _ngZone: NgZone,
    ) {

    }

    process(data) {
        if(!data) {
            return;
        }
        // User data
        if(data['@type'] == 'User') {
            //this._userService.user = data;
            const confirmation = this._fuseConfirmationService.open({
                title: "Mise à jour",
                message: "Votre compte a été mis à jour. Vous devez recharger la page pour appliquer la mise à jour.",
                actions: {
                  confirm: {
                    label: 'Recharger maintenant',
                  },
                  cancel: {
                    label: 'Recharger dans 30s',
                  },
                },
              });
          
              confirmation.afterClosed().subscribe((result) => {
                let timeout = result === 'confirmed' ? 0 : 30000;
                setTimeout(() => {
                  window.location.reload();
                }, timeout);
              });
        }
    }

}