import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, throwError } from 'rxjs';
import { UserService } from '../user/user.service';
import { User } from '../interfaces/user';
import { retry, mergeMap } from 'rxjs/operators';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const userService = inject(UserService);
    const toastr = inject(ToastrService);
    const router = inject(Router);

    const url = req.url;
    const tenant = JSON.parse(localStorage.getItem('tenant') ?? '{}');
    let user: User;

    userService.user$.subscribe(u => {
        user = u;
    })

    let headers = req.headers;
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) && !url.includes('/api/token/refresh') && !url.includes('.well-known')) {
        headers = headers.set('Authorization', 'Bearer ' + authService.accessToken);  
    }
    if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) && !url.includes('.well-known') && tenant && tenant.id) {
        headers = headers.set('tenant-id', tenant.id.toString());
    }

    const newReq = req.clone({ headers });

    return next(newReq).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse) {
                if (error.error.message?.includes('Base table or view not found')) {
                    if (user.roles.includes('ROLE_SUPER_ADMIN')) {
                        toastr.error('Aucun tenant configuré', 'Une erreur est survenue');
                        router.navigate(['/administration']);
                    } else {
                        toastr.error('Aucun tenant configuré', 'Une erreur est survenue');
                        router.navigate(['/no-tenant-configured']);
                    }
                    return throwError(() => error);
                }

                if (url.includes('token/refresh')) {
                    router.navigate(['/sign-out']);
                    return throwError(() => error);
                }
                if (error.status === 401 && error.error.message.includes('Invalid credentials')) {
                    router.navigate(['/sign-out']);
                    return throwError(() => error);
                }
                if (error.error.message?.includes('Tenant inexistant')) {
                    router.navigate(['/sign-out']);
                    return throwError(() => error);
                }
                if (error.error.hasError) {
                    const regex = /Entity of type 'App\\Entity\\Main\\TenantDbConfig' for IDs id\((\d+)\) was not found/;
                    const match = error.error.message.match(regex);
                    if (match || error.error.message === 'ACCOUNT_DEACTIVATE') {
                        toastr.error('Votre compte est désactivé', 'Une erreur est survenue');
                        router.navigate(['/sign-out']);
                        return throwError(() => error);
                    }
                    if (error.error.message === 'CHANGE DEFAULT PASSWORD') {
                        toastr.error('Veuillez changer votre mot de passe par défaut', 'Une erreur est survenue');
                        router.navigate(['/change-default-password']);
                        return throwError(() => error);
                    }
                    toastr.error(error.error.message, 'Une erreur est survenue');
                }
                if (error.status === 401 && !url.includes('sign-in')) {
                    return authService.signInUsingToken().pipe(
                        mergeMap(() => {
                            const updatedHeaders = newReq.headers.set('Authorization', 'Bearer ' + authService.accessToken);
                            const updatedReq = newReq.clone({ headers: updatedHeaders });
                            return next(updatedReq);
                        }),
                        catchError(refreshError => {
                            router.navigate(['/sign-out']);
                            return throwError(() => refreshError);
                        })
                    );
                }
                return throwError(() => error);
            }
            return throwError(() => error);
        }),
        retry({
            count: 3,
            delay: (error, retryCount) => {
                if (error instanceof HttpErrorResponse && error.status === 401 && !url.includes('sign-in')) {
                    retryCount++;
                    if (retryCount >= 3) {
                        router.navigate(['/sign-out']);
                        return throwError(() => error);
                    }
                    return authService.signInUsingToken();
                }
                if (url.includes('sign-in')) {
                    router.navigate(['/sign-out']);
                    return throwError(() => error);
                }
                return throwError(() => error);
            }
        })
    );
};
