import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { L10n, loadCldr, registerLicense, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import { CurrencyService } from './modules/params/currency/currency.service';
import { UserService } from './core/user/user.service';
import { AuthService } from './core/auth/auth.service';
import { switchMap } from 'rxjs';
import { SseService } from './core/services/sse.service';
import { EventMessageManager } from './core/services/eventMessageManager.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    /**
     * Constructor
     */
    constructor(
        private _currencyService: CurrencyService,
        private _userService: UserService,
        private _eventManager: EventMessageManager,
        private sseService: SseService,
        private oidcSecurityService: OidcSecurityService,
        private authService: AuthService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
    ) {

    }

    ngOnInit(): void {
        registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpfcXZVQmdeVUZ+W0Q=');
        setCulture('fr');

        L10n.load({
            'fr': {
                'grid': {
                    'EmptyRecord': 'Aucune donnée',
                    'GroupDropArea': 'Glissez un en-tête de colonne ici pour grouper la colonne',
                    'UnGroup': 'Cliquez ici pour annuler le regroupement',
                    'EmptyDataSourceError': 'Le DataSource ne peut pas être vide lors du premier chargement car les colonnes du dataSource sont générées automatiquement dans la grille de colonnes',
                    'Item': 'Article',
                    'Items': 'Articles',
                    'FilterButton': 'Filtrer',
                    'ClearButton': 'Effacer',
                    'StartsWith': 'Commence par',
                    'EndsWith': 'Se termine par',
                    'Contains': 'Contient',
                    'Equal': 'Egal à',
                    'NotEqual': 'Différent de',
                    'LessThan': 'Inférieur à',
                    'LessThanOrEqual': 'Inférieur ou égal à',
                    'GreaterThan': 'Supérieur à',
                    'GreaterThanOrEqual': 'Supérieur ou égal à',
                    'ChooseDate': 'Choisir une date',
                    'EnterValue': 'Saisir la valeur',
                    'SelectAll': 'Sélectionnez tout',
                    'NoResult': 'Aucun résultat trouvé',
                    'Between': 'Entre',
                    'NotStartsWith': 'Ne commence pas par',
                    'NotEndsWith': 'Ne se termine pas par',
                    'NotContains': 'Ne contient pas',
                    'IsNull': 'Est nul',
                    'NotNull': 'N\'est pas nul',
                    'IsEmpty': 'Est vide',
                    'IsNotEmpty': 'N\'est pas vide',
                    'Excelexport': 'Export Excel',
                    'Pdfexport': 'Export PDF',
                    'Csvexport': 'Export CSV',
                    'Print': 'Imprimer',
                    'Columnchooser': 'Colonnes',
                    'ChooseColumns': 'Sélectionner colonnes',
                    'Cancel': 'Annuler',
                    'CancelButton': 'Annuler',
                    'SaveButton': 'Enregistrer',
                    'Search': 'Rechercher',
                    'ClearFilter': 'Effacer le filtre',
                    'NumberFilter': 'Filtre nombre',
                    'TextFilter': 'Filtre texte',
                    'DateFilter': 'Filtre date',
                    'DateTimeFilter': 'Filtre date et heure',
                    'CustomFilter': 'Filtre personnalisé',
                    'AND': 'ET',
                    'OR': 'OU',
                    'MatchCase': 'Correspond à',
                    'ShowRowsWhere': 'Afficher les lignes où',
                    'CustomFilterPlaceHolder': 'Saisir la valeur',

                },
                'pager': {
                    'currentPageInfo': '{0} sur {1} pages',
                    'totalItemsInfo': '({0} entrées)',
                    'firstPageTooltip': 'Vers la première page',
                    'lastPageTooltip': 'Vers la dernière page',
                    'nextPageTooltip': 'Vers la page suivante',
                    'previousPageTooltip': 'Revenir à la page précédente',
                    'nextPagerTooltip': 'Vers le prochain pager',
                    'previousPagerTooltip': 'Vers le pager précédent',
                    'itemsPerPage': 'Eléments par page',
                },
                'spreadsheet': {
                    'File': 'Fichier',
                    'Home': 'Accueil',
                    'Insert': 'Insérer',
                    'Formulas': 'Formules',
                    'Data': 'Les données',
                    'View': 'Vue',
                    'Cut': 'Coupe',
                    'Copy': 'Copie',
                    'Paste': 'Pâte',
                    'PasteSpecial': 'Pâte spéciale',
                    'All': 'Tous les',
                    'Values': 'Valeurs',
                    'Formats': 'Les formats',
                    'Font': 'fonte',
                    'FontSize': 'Taille de police',
                    'Bold': 'Audacieux',
                    'Italic': 'Italique',
                    'Underline': 'Souligner',
                    'Strikethrough': 'Barré',
                    'TextColor': 'Couleur du texte',
                    'FillColor': 'La couleur de remplissage',
                    'HorizontalAlignment': 'Alignement horizontal',
                    'AlignLeft': 'Alignez à gauche',
                    'AlignCenter': 'centre',
                    'AlignRight': 'Aligner à droite',
                    'VerticalAlignment': 'Alignement vertical',
                    'AlignTop': 'Aligner en haut',
                    'AlignMiddle': 'Aligner le milieu',
                    'AlignBottom': 'Aligner le bas',
                    'InsertFunction': 'Insérer une fonction',
                    'Delete': 'Effacer',
                    'Rename': 'Rebaptiser',
                    'Hide': 'Cacher',
                    'Unhide': 'Démasquer',
                    'NumberFormat': 'Nombre Format',
                },
                'pivotview': {
                    "grandTotal": "Total général",
                    "total": "Total",
                    "value": "Valeur",
                    "noValue": "Aucune valeur",
                    "row": "Ligne",
                    "column": "Colonne",
                    "collapse": "Réduire",
                    "expand": "Développer",
                    "rowAxisPrompt": "Déposez la ligne ici",
                    "columnAxisPrompt": "Déposez la colonne ici",
                    "valueAxisPrompt": "Déposez la valeur ici",
                    "filterAxisPrompt": "Déposez le filtre ici",
                    "filter": "Filtrer",
                    "filtered": "Filtré",
                    "sort": "Trier",
                    "filters": "Filtres",
                    "rows": "Lignes",
                    "columns": "Colonnes",
                    "values": "Valeurs",
                    "close": "Fermer",
                    "cancel": "Annuler",
                    "delete": "Supprimer",
                    "CalculatedField": "Champs calculés",
                    "createCalculatedField": "Créer un champ calculé",
                    "fieldName": "Entrez le nom du champ",
                    "error": "Erreur",
                    "invalidFormula": "Formule invalide.",
                    "dropText": "Exemple : ('Somme(Nombre_Commandes)' + 'Somme(En_Stock)') * 250",
                    "dropTextMobile": "Ajoutez des champs et modifiez la formule ici.",
                    "dropAction": "Le champ calculé ne peut pas être placé dans une autre région que l'axe des valeurs.",
                    "alert": "Alerte",
                    "warning": "Avertissement",
                    "ok": "OK",
                    "search": "Rechercher",
                    "drag": "Glisser",
                    "remove": "Retirer",
                    "Sum": "Somme",
                    "Avg": "Moyenne",
                    "Count": "Nombre",
                    "Min": "Min",
                    "Max": "Max",
                    "allFields": "Tous les champs",
                    "formula": "Formule",
                    "addToRow": "Ajouter à la ligne",
                    "addToColumn": "Ajouter à la colonne",
                    "addToValue": "Ajouter à la valeur",
                    "addToFilter": "Ajouter au filtre",
                    "emptyData": "Aucun enregistrement à afficher",
                    "fieldExist": "Un champ portant ce nom existe déjà. Veuillez entrer un nom différent.",
                    "confirmText": "Un champ calculé portant ce nom existe déjà. Voulez-vous le remplacer ?",
                    "noMatches": "Aucun résultat",
                    "format": "Résumer les valeurs par",
                    "edit": "Modifier",
                    "clear": "Effacer",
                    "formulaField": "Faites glisser et déposez des champs pour créer une expression. Et, si vous souhaitez modifier les champs calculés existants ! Alors vous pouvez le faire en sélectionnant simplement le champ sous 'Membres calculés'.",
                    "dragField": "Faites glisser le champ pour créer une formule",
                    "clearFilter": "Effacer",
                    "by": "par",
                    "all": "Tout",
                    "multipleItems": "Éléments multiples",
                    "member": "Membre",
                    "label": "Étiquette",
                    "date": "Date",
                    "enterValue": "Entrer une valeur",
                    "chooseDate": "Sélectionner une date",
                    "Before": "Avant",
                    "BeforeOrEqualTo": "Avant ou égal à",
                    "After": "Après",
                    "AfterOrEqualTo": "Après ou égal à",
                    "labelTextContent": "Afficher les éléments pour lesquels l'étiquette",
                    "dateTextContent": "Afficher les éléments pour lesquels la date",
                    "valueTextContent": "Afficher les éléments pour lesquels",
                    "Equals": "Égal",
                    "DoesNotEquals": "Ne correspond pas",
                    "BeginWith": "Commence par",
                    "DoesNotBeginWith": "Ne commence pas par",
                    "EndsWith": "Se termine par",
                    "DoesNotEndsWith": "Ne se termine pas par",
                    "Contains": "Contient",
                    "DoesNotContains": "Ne contient pas",
                    "GreaterThan": "Supérieur à",
                    "GreaterThanOrEqualTo": "Supérieur ou égal à",
                    "LessThan": "Inférieur à",
                    "LessThanOrEqualTo": "Inférieur ou égal à",
                    "Between": "Entre",
                    "NotBetween": "Pas entre",
                    "And": "et",
                    "DistinctCount": "Nombre distinct",
                    "Product": "Produit",
                    "SampleVar": "Var. d'échantillon",
                    "PopulationVar": "Var. de population",
                    "RunningTotals": "Totaux cumulés",
                    "Index": "Indice",
                    "SampleStDev": "Écart-type d'échantillon",
                    "PopulationStDev": "Écart-type de population",
                    "PercentageOfRowTotal": "% du total de la ligne",
                    "PercentageOfParentTotal": "% du total parent",
                    "PercentageOfParentColumnTotal": "% du total de la colonne parent",
                    "PercentageOfParentRowTotal": "% du total de la ligne parent",
                    "DifferenceFrom": "Différence de",
                    "PercentageOfDifferenceFrom": "% de différence de",
                    "PercentageOfGrandTotal": "% du total général",
                    "PercentageOfColumnTotal": "% du total de la colonne",
                    "NotEquals": "N'est pas égal",
                    "AllValues": "Toutes les valeurs",
                    "conditionalFormatting": "Mise en forme conditionnelle",
                    "apply": "APPLIQUER",
                    "condition": "Ajouter une condition",
                    "formatLabel": "Format",
                    "valueFieldSettings": "Paramètres du champ de valeur",
                    "baseField": "Champ de base :",
                    "baseItem": "Élément de base :",
                    "summarizeValuesBy": "Résumer les valeurs par :",
                    "sourceName": "Nom du champ :",
                    "sourceCaption": "Légende du champ :",
                    "example": "ex :",
                    "editorDataLimitMsg": "plus d'éléments. Recherchez pour affiner davantage.",
                    "details": "Détails",
                    "manageRecords": "Gérer les enregistrements",
                    "Years": "Années",
                    "Quarters": "Trimestres",
                    "Months": "Mois",
                    "Days": "Jours",
                    "Hours": "Heures",
                    "Minutes": "Minutes",
                    "Seconds": "Secondes",
                    "save": "Enregistrer un rapport",
                    "new": "Créer un nouveau rapport",
                    "load": "Charger",
                    "saveAs": "Enregistrer sous",
                    "rename": "Renommer le rapport actuel",
                    "deleteReport": "Supprimer le rapport actuel",
                    "export": "Exporter",
                    "subTotals": "Sous-totaux",
                    "grandTotals": "Totals généraux",
                    "reportName": "Nom du rapport :",
                    "pdf": "PDF",
                    "excel": "Excel",
                    "csv": "CSV",
                    "png": "PNG",
                    "jpeg": "JPEG",
                    "svg": "SVG",
                    "mdxQuery": "Requête MDX",
                    "showSubTotals": "Afficher les sous-totaux",
                    "doNotShowSubTotals": "Ne pas afficher les sous-totaux",
                    "showSubTotalsRowsOnly": "Afficher uniquement les lignes de sous-totaux",
                    "showSubTotalsColumnsOnly": "Afficher uniquement les colonnes de sous-totaux",
                    "showGrandTotals": "Afficher les totaux généraux",
                    "doNotShowGrandTotals": "Ne pas afficher les totaux généraux",
                    "showGrandTotalsRowsOnly": "Afficher uniquement les lignes de totaux généraux",
                    "showGrandTotalsColumnsOnly": "Afficher uniquement les colonnes de totaux généraux",
                    "fieldList": "Afficher la liste des champs",
                    "grid": "Afficher le tableau",
                    "toolbarFormatting": "Mise en forme conditionnelle",
                    "chart": "Graphique",
                    "reportMsg": "Veuillez saisir un nom de rapport valide !!!",
                    "reportList": "Liste des rapports",
                    "removeConfirm": "Êtes-vous sûr de vouloir supprimer ce rapport ?",
                    "emptyReport": "Aucun rapport trouvé !!",
                    "bar": "Barre",
                    "line": "Ligne",
                    "area": "Zone",
                    "scatter": "Dispersion",
                    "polar": "Polaire",
                    "of": "de",
                    "emptyFormat": "Aucun format trouvé !!!",
                    "emptyInput": "Saisissez une valeur",
                    "newReportConfirm": "Voulez-vous enregistrer les modifications apportées au rapport ?",
                    "emptyReportName": "Saisissez un nom de rapport",
                    "qtr": "Trim.",
                    "null": "null",
                    "undefined": "indéfini",
                    "groupOutOfRange": "Hors de portée",
                    "fieldDropErrorAction": "Le champ que vous déplacez ne peut pas être placé dans cette zone du rapport",
                    "MoreOption": "Plus…",
                    "aggregate": "Agrégat",
                    "drillThrough": "Forage",
                    "ascending": "Croissant",
                    "descending": "Décroissant",
                    "number": "Nombre",
                    "currency": "Devise",
                    "percentage": "Pourcentage",
                    "formatType": "Type de format",
                    "customText": "Symbole de devise",
                    "symbolPosition": "Position du symbole",
                    "left": "Gauche",
                    "right": "Droite",
                    "grouping": "Regroupement",
                    "true": "Vrai",
                    "false": "Faux",
                    "decimalPlaces": "Décimales",
                    "numberFormat": "Format de nombre",
                    "memberType": "Type de champ",
                    "formatString": "Chaîne de format",
                    "expressionField": "Expression",
                    "customFormat": "Entrer une chaîne de format personnalisée",
                    "selectedHierarchy": "Hiérarchie parente",
                    "olapDropText": "Exemple : [Mesures].[Quantité de commande] + ([Mesures].[Quantité de commande] * 0.10)",
                    "Percent": "Pourcentage",
                    "Custom": "Personnalisé",
                    "Measure": "Mesure",
                    "Dimension": "Dimension",
                    "Standard": "Standard",
                    "blank": "(Vide)",
                    "fieldTooltip": "Faites glisser et déposez des champs pour créer une expression. Et, si vous souhaitez modifier les champs calculés existants, vous pouvez le faire en sélectionnant simplement le champ sous 'Membres calculés'.",
                    "QuarterYear": "Trimestre Année",
                    "fieldTitle": "Nom du champ",
                    "drillError": "Impossible d'afficher les éléments bruts des champs calculés.",
                    "caption": "Légende du champ",
                    "copy": "Copier",
                    "defaultReport": "Rapport par défaut",
                    "customFormatString": "Format personnalisé",
                    "invalidFormat": "Format invalide.",
                    "group": "Grouper",
                    "unGroup": "Dégrouper",
                    "invalidSelection": "Impossible de regrouper cette sélection.",
                    "groupName": "Saisissez la légende à afficher dans l'en-tête",
                    "captionName": "Saisissez la légende pour le champ de groupe",
                    "selectedItems": "Éléments sélectionnés",
                    "groupFieldCaption": "Légende du champ",
                    "groupTitle": "Nom du groupe",
                    "startAt": "Commence à",
                    "endAt": "Termine à",
                    "groupBy": "Intervalle par",
                    "selectGroup": "Sélectionner des groupes",
                    "numberFormatString": "Exemple : C, P, 0000 %, ###0.##0#, etc.",
                    "stackingcolumn": "Colonnes empilées",
                    "stackingbar": "Barres empilées",
                    "stackingarea": "Aires empilées",
                    "stepline": "Ligne en escalier",
                    "steparea": "Zone en escalier",
                    "splinearea": "Aire en spline",
                    "spline": "Spline",
                    "stackingcolumn100": "Colonnes empilées à 100%",
                    "stackingbar100": "Barres empilées à 100%",
                    "stackingarea100": "Aires empilées à 100%",
                    "bubble": "Bulle",
                    "pareto": "Pareto",
                    "radar": "Radar",
                    "chartTypeSettings": "Paramètres du type de graphique",
                    "multipleAxes": "Axes multiples",
                    "sortAscending": "Trier par ordre croissant",
                    "sortDescending": "Trier par ordre décroissant",
                    "sortNone": "Trier l'ordre des données",
                    "clearCalculatedField": "Effacer les informations de champ modifié",
                    "editCalculatedField": "Modifier le champ calculé",
                    "ChartType": "Type de graphique",
                    "yes": "Oui",
                    "no": "Non",
                    "numberFormatMenu": "Formatage des nombres...",
                    "conditionalFormattingMenu": "Formatage conditionnel...",
                    "removeCalculatedField": "Êtes-vous sûr de vouloir supprimer ce champ calculé ?",
                    "replaceConfirmBefore": "Un rapport nommé",
                    "replaceConfirmAfter": "existe déjà. Voulez-vous le remplacer ?",
                    "pie": "Camembert",
                    "funnel": "Entonnoir",
                    "doughnut": "Donut",
                    "pyramid": "Pyramide",
                    "showLegend": "Afficher la légende",
                    "exit": "Quitter",
                    "invalidJSON": "Données JSON invalides",
                    "invalidCSV": "Données CSV invalides",
                    "stacked": "Empilé",
                    "single": "Unique",
                    "multipleAxisMode": "Mode à axes multiples",
                    "grandTotalPosition": "Position des totaux généraux",
                    "top": "Haut",
                    "bottom": "Bas",
                    "None": "Aucun",
                    "stackingline": "Ligne empilée",
                    "stackingline100": "Ligne empilée à 100%",
                    "rowPage": "Pagination des lignes",
                    "rowPerPage": "Lignes par page",
                    "columnPage": "Pagination des colonnes",
                    "columnPerPage": "Colonnes par page",
                    "goToFirstPage": "Aller à la première page",
                    "goToPreviousPage": "Aller à la page précédente",
                    "goToNextPage": "Aller à la page suivante",
                    "goToLastPage": "Aller à la dernière page",
                    "combined": "Combiné",
                    "subTotalPosition": "Position des sous-totaux",
                    "auto": "Auto"
                },
                'pivotfieldlist': {
                    "staticFieldList": "Liste des champs pivot",
                    "fieldList": "Liste des champs",
                    "dropFilterPrompt": "Déposez le filtre ici",
                    "dropColPrompt": "Déposez la colonne ici",
                    "dropRowPrompt": "Déposez la ligne ici",
                    "dropValPrompt": "Déposez la valeur ici",
                    "addPrompt": "Ajouter un champ ici",
                    "adaptiveFieldHeader": "Choisir un champ",
                    "centerHeader": "Glissez les champs entre les axes ci-dessous :",
                    "add": "Ajouter",
                    "drag": "Glisser",
                    "filter": "Filtrer",
                    "filtered": "Filtré",
                    "sort": "Trier",
                    "remove": "Supprimer",
                    "filters": "Filtres",
                    "rows": "Lignes",
                    "columns": "Colonnes",
                    "values": "Valeurs",
                    "CalculatedField": "Champs calculés",
                    "createCalculatedField": "Créer un champ calculé",
                    "fieldName": "Entrez le nom du champ",
                    "error": "Erreur",
                    "invalidFormula": "Formule invalide.",
                    "dropText": "Exemple : (‘Somme(Quantité_Commandée)’ + ‘Somme(En_Stock)’) * 250",
                    "dropTextMobile": "Ajoutez des champs et modifiez la formule ici.",
                    "dropAction": "Le champ calculé ne peut être placé que dans l'axe des valeurs.",
                    "search": "Recherche",
                    "close": "Fermer",
                    "cancel": "Annuler",
                    "delete": "Supprimer",
                    "alert": "Alerte",
                    "warning": "Avertissement",
                    "ok": "OK",
                    "Sum": "Somme",
                    "Avg": "Moyenne",
                    "Count": "Décompte",
                    "Min": "Min",
                    "Max": "Max",
                    "allFields": "Tous les champs",
                    "formula": "Formule",
                    "fieldExist": "Un champ existe déjà avec ce nom. Veuillez saisir un nom différent.",
                    "confirmText": "Un champ calculé existe déjà avec ce nom. Voulez-vous le remplacer ?",
                    "noMatches": "Aucune correspondance",
                    "format": "Résumer les valeurs par",
                    "edit": "Modifier",
                    "clear": "Effacer",
                    "formulaField": "Glisser et déposer les champs dans la formule",
                    "dragField": "Glisser le champ dans la formule",
                    "clearFilter": "Effacer",
                    "by": "par",
                    "enterValue": "Entrer une valeur",
                    "chooseDate": "Saisissez la date",
                    "all": "Tous",
                    "multipleItems": "Éléments multiples",
                    "Equals": "Égal à",
                    "DoesNotEquals": "N'est pas égal à",
                    "BeginWith": "Commence par",
                    "DoesNotBeginWith": "Ne commence pas par",
                    "EndsWith": "Se termine par",
                    "DoesNotEndsWith": "Ne se termine pas par",
                    "Contains": "Contient",
                    "DoesNotContains": "Ne contient pas",
                    "GreaterThan": "Supérieur à",
                    "GreaterThanOrEqualTo": "Supérieur ou égal à",
                    "LessThan": "Inférieur à",
                    "LessThanOrEqualTo": "Inférieur ou égal à",
                    "Between": "Entre",
                    "NotBetween": "Pas entre",
                    "Before": "Avant",
                    "BeforeOrEqualTo": "Avant ou égal à",
                    "After": "Après",
                    "AfterOrEqualTo": "Après ou égal à",
                    "member": "Membre",
                    "label": "Étiquette",
                    "date": "Date",
                    "value": "Valeur",
                    "labelTextContent": "Afficher les éléments pour lesquels l'étiquette",
                    "dateTextContent": "Afficher les éléments pour lesquels la date",
                    "valueTextContent": "Afficher les éléments pour lesquels",
                    "And": "et",
                    "DistinctCount": "Nombre distinct",
                    "Product": "Produit",
                    "Index": "Index",
                    "SampleStDev": "Écart type (échantillon)",
                    "PopulationStDev": "Écart type (population)",
                    "SampleVar": "Variance (échantillon)",
                    "PopulationVar": "Variance (population)",
                    "RunningTotals": "Totals cumulés",
                    "DifferenceFrom": "Différence de",
                    "PercentageOfDifferenceFrom": "% de différence de",
                    "PercentageOfGrandTotal": "% du total général",
                    "PercentageOfColumnTotal": "% du total de la colonne",
                    "PercentageOfRowTotal": "% du total de la ligne",
                    "PercentageOfParentTotal": "% du total parent",
                    "PercentageOfParentColumnTotal": "% du total de la colonne parent",
                    "PercentageOfParentRowTotal": "% du total de la ligne parent",
                    "Years": "Années",
                    "Quarters": "Trimestres",
                    "Months": "Mois",
                    "Days": "Jours",
                    "Hours": "Heures",
                    "Minutes": "Minutes",
                    "Seconds": "Secondes",
                    "apply": "APPLIQUER",
                    "valueFieldSettings": "Paramètres du champ de valeur",
                    "sourceName": "Nom du champ :",
                    "sourceCaption": "Légende du champ :",
                    "summarizeValuesBy": "Résumer les valeurs par :",
                    "baseField": "Champ de base :",
                    "baseItem": "Élément de base :",
                    "example": "par ex. :",
                    "editorDataLimitMsg": "plus d'éléments. Recherchez pour affiner davantage.",
                    "deferLayoutUpdate": "Report différé",
                    "null": "null",
                    "undefined": "indéfini",
                    "groupOutOfRange": "Hors de portée",
                    "fieldDropErrorAction": "Le champ que vous déplacez ne peut pas être placé dans cette zone du rapport",
                    "MoreOption": "Plus…",
                    "memberType": "Type de champ",
                    "selectedHierarchy": "Hiérarchie parente",
                    "formatString": "Chaîne de format",
                    "expressionField": "Expression",
                    "olapDropText": "Exemple : [Mesures].[Quantité de commande] + ([Mesures].[Quantité de commande] * 0.10)",
                    "customFormat": "Entrez une chaîne de format personnalisée",
                    "Measure": "Mesure",
                    "Dimension": "Dimension",
                    "Standard": "Standard",
                    "Currency": "Devise",
                    "Percent": "Pourcentage",
                    "Custom": "Personnalisé",
                    "blank": "(Vide)",
                    "fieldTooltip": "Glissez et déposez des champs pour créer une expression. Et si vous voulez modifier les champs calculés existants ! Vous pouvez le faire en sélectionnant simplement le champ sous 'Membres calculés'.",
                    "fieldTitle": "Nom du champ",
                    "QuarterYear": "Trimestre Année",
                    "caption": "Légende de champ",
                    "copy": "Copier",
                    "group": "Grouper",
                    "numberFormatString": "Exemple : C, P, 0000 %, ###0.##0#, etc.",
                    "sortAscending": "Trier par ordre croissant",
                    "sortDescending": "Trier par ordre décroissant",
                    "sortNone": "Trier l'ordre des données",
                    "clearCalculatedField": "Effacer les informations du champ modifié",
                    "editCalculatedField": "Modifier le champ calculé",
                    "selectGroup": "Sélectionner des groupes",
                    "of": "de",
                    "removeCalculatedField": "Êtes-vous sûr de vouloir supprimer ce champ calculé ?",
                    "yes": "Oui",
                    "no": "Non",
                    "None": "Aucun"
                }
            },
            'fr-FR': {
                'richtexteditor': {
                    alignments: "Alignements",
                    justifyLeft: "Aligner à gauche",
                    justifyCenter: "Aligner au centre",
                    justifyRight: "Aligner à droite",
                    justifyFull: "Justifier",
                    fontName: "Nom de la police",
                    fontSize: "Taille de la police",
                    fontColor: "Couleur de la police",
                    backgroundColor: "Couleur de fond",
                    bold: "Gras",
                    italic: "Italique",
                    underline: "Souligner",
                    strikethrough: "Barré",
                    clearFormat: "Effacer le format",
                    clearAll: "Tout effacer",
                    cut: "Couper",
                    copy: "Copier",
                    paste: "Coller",
                    unorderedList: "Liste à puces",
                    orderedList: "Liste numérotée",
                    indent: "Augmenter le retrait",
                    outdent: "Diminuer le retrait",
                    undo: "Annuler",
                    redo: "Rétablir",
                    superscript: "Exposant",
                    subscript: "Indice",
                    createLink: "Insérer un lien",
                    openLink: "Ouvrir le lien",
                    editLink: "Modifier le lien",
                    removeLink: "Supprimer le lien",
                    image: "Insérer une image",
                    replace: "Remplacer",
                    align: "Aligner",
                    caption: "Légende de l'image",
                    remove: "Supprimer",
                    insertLink: "Insérer un lien",
                    display: "Afficher",
                    altText: "Texte alternatif",
                    dimension: "Changer la taille",
                    fullscreen: "Plein écran",
                    maximize: "Maximiser",
                    minimize: "Minimiser",
                    lowerCase: "Minuscule",
                    upperCase: "Majuscule",
                    print: "Imprimer",
                    formats: "Formats",
                    sourcecode: "Afficher le code",
                    preview: "Aperçu",
                    viewside: "Vue latérale",
                    insertCode: "Insérer le code",
                    linkText: "Texte affiché",
                    linkTooltipLabel: "Titre",
                    linkWebUrl: "Adresse Web",
                    linkTitle: "Entrer un titre",
                    linkurl: "http://example.com",
                    linkOpenInNewWindow: "Ouvrir le lien dans une nouvelle fenêtre",
                    linkHeader: "Insérer un lien",
                    dialogInsert: "Insérer",
                    dialogCancel: "Annuler",
                    dialogUpdate: "Mettre à jour",
                    imageHeader: "Insérer une image",
                    imageLinkHeader: "Vous pouvez également fournir un lien depuis le web",
                    mdimageLink: "Veuillez fournir une URL pour votre image",
                    imageUploadMessage: "Déposez l'image ici ou parcourez pour télécharger",
                    imageDeviceUploadMessage: "Cliquez ici pour télécharger",
                    imageAlternateText: "Texte alternatif",
                    alternateHeader: "Texte alternatif",
                    browse: "Parcourir",
                    imageUrl: "http://example.com/image.png",
                    imageCaption: "Légende",
                    imageSizeHeader: "Taille de l'image",
                    imageHeight: "Hauteur",
                    imageWidth: "Largeur",
                    textPlaceholder: "Entrer le texte",
                    inserttablebtn: "Insérer un tableau",
                    tabledialogHeader: "Insérer un tableau",
                    tableWidth: "Largeur",
                    cellpadding: "Espacement des cellules",
                    cellspacing: "Interligne des cellules",
                    columns: "Nombre de colonnes",
                    rows: "Nombre de lignes",
                    tableRows: "Lignes du tableau",
                    tableColumns: "Colonnes du tableau",
                    tableCellHorizontalAlign: "Alignement horizontal des cellules",
                    tableCellVerticalAlign: "Alignement vertical des cellules",
                    createTable: "Créer un tableau",
                    removeTable: "Supprimer le tableau",
                    tableHeader: "En-tête du tableau",
                    tableRemove: "Supprimer le tableau",
                    tableCellBackground: "Arrière-plan des cellules",
                    tableEditProperties: "Modifier les propriétés du tableau",
                    styles: "Styles",
                    insertColumnLeft: "Insérer une colonne à gauche",
                    insertColumnRight: "Insérer une colonne à droite",
                    deleteColumn: "Supprimer la colonne",
                    insertRowBefore: "Insérer une ligne avant",
                    insertRowAfter: "Insérer une ligne après",
                    deleteRow: "Supprimer la ligne",
                    tableEditHeader: "Modifier le tableau",
                    TableHeadingText: "En-tête",
                    TableColText: "Col",
                    imageInsertLinkHeader: "Insérer un lien",
                    editImageHeader: "Modifier l'image",
                    alignmentsDropDownLeft: "Aligner à gauche",
                    alignmentsDropDownCenter: "Aligner au centre",
                    alignmentsDropDownRight: "Aligner à droite",
                    alignmentsDropDownJustify: "Justifier",
                    imageDisplayDropDownInline: "En ligne",
                    imageDisplayDropDownBreak: "Retour à la ligne",
                    tableInsertRowDropDownBefore: "Insérer une ligne avant",
                    tableInsertRowDropDownAfter: "Insérer une ligne après",
                    tableInsertRowDropDownDelete: "Supprimer la ligne",
                    tableInsertColumnDropDownLeft: "Insérer une colonne à gauche",
                    tableInsertColumnDropDownRight: "Insérer une colonne à droite",
                    tableInsertColumnDropDownDelete: "Supprimer la colonne",
                    tableVerticalAlignDropDownTop: "Aligner en haut",
                    tableVerticalAlignDropDownMiddle: "Aligner au milieu",
                    tableVerticalAlignDropDownBottom: "Aligner en bas",
                    tableStylesDropDownDashedBorder: "Bordures en pointillé",
                    tableStylesDropDownAlternateRows: "Lignes alternées",
                    pasteFormat: "Coller le format",
                    pasteFormatContent: "Choisir l'action de formatage",
                    plainText: "Texte brut",
                    cleanFormat: "Nettoyer",
                    keepFormat: "Conserver",
                    formatsDropDownParagraph: "Paragraphe",
                    formatsDropDownCode: "Code",
                    formatsDropDownQuotation: "Citation",
                    formatsDropDownHeading1: "Titre 1",
                    formatsDropDownHeading2: "Titre 2",
                    formatsDropDownHeading3: "Titre 3",
                    formatsDropDownHeading4: "Titre 4",
                    fontNameSegoeUI: "Segoe UI",
                    fontNameArial: "Arial",
                    fontNameGeorgia: "Georgia",
                    fontNameImpact: "Impact",
                    fontNameTahoma: "Tahoma",
                    fontNameTimesNewRoman: "Times New Roman",
                    fontNameVerdana: "Verdana",
                    numberFormatListNumber: 'Numéro',
                    numberFormatListLowerAlpha: 'AlphaMinuscule',
                    numberFormatListUpperAlpha: 'AlphaMajuscule',
                    numberFormatListLowerRoman: 'RomainMinuscule',
                    numberFormatListUpperRoman: 'RomainMajuscule',
                    numberFormatListLowerGreek: 'GrecMinuscule',
                    bulletFormatListDisc: 'Disque',
                    bulletFormatListCircle: 'Cercle',
                    bulletFormatListSquare: 'Carré',
                    numberFormatListNone: 'Aucun',
                    bulletFormatListNone: 'Aucun',
                    formatPainter: 'Reproduire la mise en forme',
                    emojiPicker: 'Sélecteur d\'émojis',
                    embeddedCode: 'Code intégré',
                    pasteEmbeddedCodeHere: 'Collez le code intégré ici',
                    emojiPickerTypeToFind: 'Tapez pour trouver',
                    emojiPickerNoResultFound: 'Aucun résultat trouvé',
                    emojiPickerTrySomethingElse: 'Essayez autre chose',
                }
            }
            
        });

        this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData }) => {
            if(isAuthenticated) {
                this.authService.ssoSignIn(userData.username).subscribe(res => {
                    const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || null;

                    // Navigate to the redirect url
                    this._router.navigate(['/signed-in-redirect'], { queryParams: { redirectURL } });
                })
            }
        });


        /*this._currencyService.getItems({ 'default': true }).subscribe(res => {
            if (res['hydra:member'].length) {
                setCurrencyCode(res['hydra:member'][0].code);
            }
        })*/
        this._userService.user$.subscribe(user => {
            if (user) {
                if (this.sseService.connectionState$.subscribe((connected) => {
                    if (!connected) {
                       // this.sseService.stream(['hub', `user_${user?.id}`]);
                    }
                }))

                    this.sseService.events$.subscribe((eventData) => {
                        this._eventManager.process(eventData);
                    })

            }
        })
    }

    ngOnDestroy(): void {
        this.sseService.unsubscribe();
    }
}
