import { FuseNavigationItem } from '@fuse/components/navigation';


export const ROUTES: FuseNavigationItem[] = [

    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'collapsable',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard',
        children : [
            {
                id: 'my-dashboard',
                title: 'Mon tableau de bord',
                type: 'basic',
                link: '/profile/my-dashboard',
            },
            {
                id: 'analytics',
                title: 'Analytics',
                type: 'basic',
                link: '/dashboard/analytics',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'crc',
                title: 'CRC',
                type: 'basic',
                link: '/dashboard/crc',
                roles: ['ROLE_SALES', 'ROLE_PROJET', 'ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
            },
            {
                id: 'reporting-stutio',
                title: 'Reporting studio',
                type: 'basic',
                link: '/dashboard/reporting-studio',
                roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            }
        ]
    },

    // Commerce routing
    {
        id: 'sales',
        title: 'Commerce',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        link: '/sales/',
        roles: ['ROLE_SALES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            {
                id: 'offers',
                title: 'Offres',
                type: 'basic',
                link: '/sales/offers',
            },
            {
                id: 'quotation',
                title: 'Devis',
                type: 'basic',
                link: '/sales/quotations',
            },
            /*{
                id: 'demande',
                title: 'Demandes d\'achat',
                type: 'basic',
                link: '/sales/purchases',
            },*/
        ]
    },

     // Vente routing
     {
        id: 'production',
        title: 'Production',
        type: 'collapsable',
        icon: 'heroicons_outline:code-bracket',
        link: '/production/',
        roles: ['ROLE_SALES', 'ROLE_PROJET', 'ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            {
                id: 'projects',
                title: 'Projets',
                type: 'basic',
                link: '/production/projects',
            },
            {
                id: 'supports',
                title: 'Supports',
                type: 'basic',
                link: '/production/supports',
            },
        ]
    },

     // Achat routing
     {
        id: 'achat',
        title: 'Achat',
        type: 'collapsable',
        icon: 'heroicons_outline:currency-dollar',
        children : [
            {
                id: 'my-purchasing-requests',
                title: 'Mes demandes d\'achat',
                type: 'basic',
                link: '/purchase/my-purchasing-requests',
            },
            {
                id: 'purchasing-requests',
                title: 'Demandes à traiter',
                type: 'basic',
                link: '/purchase/purchasing-requests',
            },
        ]
    },

     // DMG routing
     {
        id: 'facilities',
        title: 'Stock',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        link: '/facilities',
        roles: ['ROLE_DMG', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            /*{
                id: 'stock',
                title: 'Stock',
                type: 'basic',
                link: '/facilities/stock',
            },*/
            {
                id: 'inventory',
                title: 'Inventaire produits',
                type: 'basic',
                link: '/facilities/inventory',
            },
            /*{
                id: 'vehicules-fleet',
                title: 'Flotte de véhicules',
                type: 'basic',
                link: '/facilities/vehicules-fleet',
            },*/
        ]
    },

     // RH routing
     {
        id: 'hr',
        title: 'RH',
        type: 'collapsable',
        icon: 'heroicons_outline:users',
        link: '/hr',
        roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            {
                id: 'employees-list',
                title: 'Liste des employés',
                type: 'basic',
                link: '/hr/employees',
                roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'employees-contracts',
                title: 'Contrats',
                type: 'basic',
                link: '/hr/contracts',
                roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
        ]
    },

    // Accounting routing
    {
        id: 'accounting',
        title: 'Comptabilité',
        type: 'collapsable',
        icon: 'heroicons_outline:calculator',
        link: '/accounting',
        roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_PROJET', 'ROLE_SALES', 'ROLE_DMG','ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            {
                id: 'client_invoices',
                title: 'Factures clients',
                type: 'basic',
                link: '/accounting/client-invoices',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_PROJET', 'ROLE_SALES','ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'supplier_invoices',
                title: 'Factures fournisseurs',
                type: 'basic',
                link: '/accounting/supplier-invoices',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_PROJET', 'ROLE_SALES', 'ROLE_DMG','ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
        ]
    },

     // Tresorerie routing
     {
        id: 'treso',
        title: 'Trésorerie',
        type: 'collapsable',
        icon: 'heroicons_outline:banknotes',
        link: '/treasury',
        roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            {
                id: 'banque',
                title: 'Banque',
                type: 'basic',
                link: '/treasury/banks',
            },
            {
                id: 'caisse',
                title: 'Caisse',
                type: 'basic',
                link: '/treasury/cash-registers',
            },
            {
                id: 'expense-lines',
                title: 'Lignes de dépenses',
                type: 'basic',
                link: '/treasury/expense-lines',
            },
        ]
    },

     // Parametrage routing
     {
        id: 'parametrage',
        title: 'Paramétrage',
        type: 'collapsable',
        icon: 'heroicons_outline:cog',
        link: '/params',
        roles: ['ROLE_SALES', 'ROLE_PROJET', 'ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
        children : [
            {
                id: 'enterprises',
                title: 'Entreprises',
                type: 'basic',
                link: '/params/enterprises',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'departments',
                title: 'Unités organisationnelles',
                type: 'basic',
                link: '/params/departments',
                roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'tenant_users',
                title: 'Utilisateurs',
                type: 'basic',
                link: '/params/users',
                roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'clients',
                title: 'Clients',
                type: 'basic',
                link: '/params/clients',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_SALES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'client-contacts',
                title: 'Contacts clients',
                type: 'basic',
                link: '/params/client-contacts',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_SALES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'suppliers',
                title: 'Fournisseurs',
                type: 'basic',
                link: '/params/suppliers',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_DMG', 'ROLE_SALES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'department-types',
                title: 'Types d\'unité organisationnelle',
                type: 'basic',
                link: '/params/department-types',
                roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'track-types',
                title: 'Types d\'offres',
                type: 'basic',
                link: '/params/track-types',
                roles: ['ROLE_SALES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'track-categories',
                title: 'Catégories d\'offres',
                type: 'basic',
                link: '/params/track-categories',
                roles: ['ROLE_SALES', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'currencies',
                title: 'Devises',
                type: 'basic',
                link: '/params/currencies',
                roles: ['ROLE_SALES', 'ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'banks',
                title: 'Banques',
                type: 'basic',
                link: '/params/banks',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'accounts',
                title: 'Comptes',
                type: 'basic',
                link: '/params/accounts',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'account-types',
                title: 'Types de comptes',
                type: 'basic',
                link: '/params/account-types',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'operation-natures',
                title: 'Natures d\'opérations',
                type: 'basic',
                link: '/params/operation-natures',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'purchase-categories',
                title: 'Catégories d\'achats',
                type: 'basic',
                link: '/params/purchase-categories',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_DMG', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'funds-origins',
                title: 'Origines des fonds',
                type: 'basic',
                link: '/params/funds-origins',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'funds-destinations',
                title: 'Destinations des fonds',
                type: 'basic',
                link: '/params/funds-destinations',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'disbursement-categories',
                title: 'Catégories des décaissements',
                type: 'basic',
                link: '/params/disbursement-categories',
                roles: ['ROLE_COMPTA', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'grids-rules',
                title: 'Règles tableaux',
                type: 'basic',
                link: '/params/grids-rules',
                roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
            },
            {
                id: 'facilities-params',
                title: 'Stock',
                type: 'collapsable',
                link: '/params/inventory',
                roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DMG'],
                children : [
                    {
                        id: 'params-product-types',
                        title: 'Types de produit',
                        type: 'basic',
                        link: '/params/inventory/product-types',
                        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DMG'],
                    },
                    {
                        id: 'params-products',
                        title: 'Produit',
                        type: 'basic',
                        link: '/params/inventory/products',
                    },
                    {
                        id: 'params-product-brands',
                        title: 'Marques de produit',
                        type: 'basic',
                        link: '/params/inventory/product-brands',
                    },
                    {
                        id: 'params-product-models',
                        title: 'Modèles de produit',
                        type: 'basic',
                        link: '/params/inventory/product-models',
                    },
                ]
            },
            {
                id: 'hr-params',
                title: 'RH',
                type: 'collapsable',
                link: '/params/hr',
                roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DMG', 'ROLE_RH'],
                children : [
                    {
                        id: 'params-hr-contract-types',
                        title: 'Types de contrat',
                        type: 'basic',
                        link: '/params/hr/contract-types',
                        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_RH'],
                    },
                    {
                        id: 'params-hr-functions',
                        title: 'Fonctions',
                        type: 'basic',
                        link: '/params/hr/functions',
                        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_RH'],
                    },
                    {
                        id: 'employees-contract-attributions',
                        title: 'Attributions contrat',
                        type: 'basic',
                        link: '/params/hr/contract-attributions',
                        roles: ['ROLE_RH', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'],
                    },
                    {
                        id: 'params-hr-work-sites',
                        title: 'Sites de travail',
                        type: 'basic',
                        link: '/params/hr/work-sites',
                        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_RH', 'ROLE_DMG'],
                    },
                    {
                        id: 'params-hr-id-card-types',
                        title: 'Types de pièce',
                        type: 'basic',
                        link: '/params/hr/id-card-types',
                        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_RH'],
                    },
                    {
                        id: 'params-hr-nationalities',
                        title: 'Nationalités',
                        type: 'basic',
                        link: '/params/hr/nationalities',
                        roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_RH'],
                    },
                ]
            },
            
        ]
    },

    {
        id: 'administration',
        title: 'Administration',
        type: 'collapsable',
        icon: 'heroicons_outline:user',
        roles: ['ROLE_SUPER_ADMIN'],
        link: '/administration',
        children : [
            {
                id: 'tenant',
                title: 'Tenants',
                type: 'basic',
                link: '/administration/tenancies',
                roles: ['ROLE_SUPER_ADMIN'],
            },
            {
                id: 'user',
                title: 'Utilisateurs',
                type: 'basic',
                link: '/administration/users',
                roles: ['ROLE_SUPER_ADMIN'],
            },
            {
                id: 'county',
                title: 'Pays',
                type: 'basic',
                link: '/administration/countries',
                roles: ['ROLE_SUPER_ADMIN'],
            }
        ]
    },


];
