<!-- Shortcuts toggle -->
<button mat-icon-button (click)="openPanel()" #shortcutsOrigin *ngIf="shortcuts && shortcuts.length > 1">
    <mat-icon [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #shortcutsPanel>
    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="flex items-center text-lg font-medium leading-10">
                <span class="">Tenants</span>
            </div>
        </div>
        <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card">

            <ng-container>

                <!-- Shortcuts -->
                <div class="grid grid-cols-2 grid-flow-row">
                    <!-- Shortcut -->
                    <ng-container *ngFor="let shortcut of shortcuts; trackBy: trackByFn">
                        <div
                            class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                            <!-- Router links -->
                            <a class="flex flex-col items-center justify-center w-full h-full py-6 no-underline cursor-pointer" (click)="selectTenant(shortcut.tenant)">
                                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                            </a>
                            <!-- Link content template -->
                            <ng-template #linkContent>
                                <div
                                    class="relative flex shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full bg-gray-100 dark:bg-gray-700">
                                    <img [src]="shortcut?.tenant?.logo?.fileUrl ? backendUrl+shortcut?.tenant?.logo?.fileUrl : '/assets/images/logo/logo-min.png'" alt="">
                                </div>
                                <div class="font-medium text-center">{{shortcut.tenant.tenantName}}</div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>

                <!-- No shortcuts -->
                <ng-container *ngIf="!shortcuts || !shortcuts.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div
                            class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                            <mat-icon class="text-primary-700 dark:text-primary-50"
                                [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">No shortcuts</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have shortcuts,
                            they will appear here.</div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>