import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Currency } from "app/core/interfaces/currency";
import { Pagination } from "app/core/interfaces/pagination";
import { RestApiClientService } from "app/core/services/rest-api-client.service";
import { BehaviorSubject, Observable, filter, map, switchMap, take, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class CurrencyService {
    // Private
    private _items: BehaviorSubject<Currency[] | null> = new BehaviorSubject(null);
    private _item: BehaviorSubject<Currency | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: RestApiClientService) {
    }


    get item$(): Observable<Currency> {
        return this._item.asObservable();
    }

    get items$(): Observable<Currency[]> {
        return this._items.asObservable();
    }

    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }


    getItems(params?: any): Observable<Currency[]> {
        return this._httpClient.get<Currency[]>('currencies', params).pipe(
            tap((items) => {
                this._items.next(items);

                const length = items['hydra:totalItems'];
                const page = (!params.page || params.page <1) ? 0 : params.page - 1 ;
                const size = params.itemsPerPage ?? 10;
                const startIndex = page * size;
                const end = Math.min((size * (page+1)), length);
                const endIndex = end-1;
                const lastPage = Math.max(Math.ceil(length / size), 1);
                
                const pagination: Pagination = {
                    page,
                    size,
                    startIndex,
                    endIndex,
                    length,
                    lastPage

                };
                this._pagination.next(pagination);
            }),
        );
    }

    getById(id: number): Observable<Currency> {
        return this._httpClient.get<Currency>('currencies/' + id).pipe(
            tap((item) => {
                this._item.next(item);
                return item;
            }),
        );
    }

    create(data: Currency): Observable<Currency> {
        return this.items$.pipe(
            take(1),
            switchMap(items => this._httpClient.post<Currency>('currencies', data).pipe(
                map((newItem) => {
                    if (items) {
                        items['hydra:member'].splice(0, 1);
                        items['hydra:member'].push(newItem);
                        this._items.next(items);
                    }
                    return newItem;
                })
            ))
        )
    }

    createNewItem(): Observable<Currency> {
        return this._items.pipe(
            take(1),
            map((items) => {
                const newItem = {};
                items['hydra:member'].unshift(newItem);
                this._items.next(items);
                return newItem;
            })
        );
    }

    update(data: Currency): Observable<Currency> {
        return this.items$.pipe(
            take(1),
            switchMap(items => this._httpClient.put<Currency>('currencies', data.id, data).pipe(
                map((updatedItem) => {
                    if (items) {
                        const index = items['hydra:member'].findIndex(i => i.id == data.id);
                        items['hydra:member'][index] = updatedItem;
                        this._items.next(items);
                    }
                    return updatedItem;
                }),
                switchMap(updatedItem => this.item$.pipe(
                    take(1),
                    filter(i => i && i.id === data.id),
                    tap(() => {
                        this._item.next(updatedItem);
                        return updatedItem;
                    })
                ))
            ))
        )
    }

    cancelCreation(): Observable<Currency[]> {
        return this._items.pipe(
            take(1),
            map((items) => {
                items['hydra:member'].splice(0, 1);
                this._items.next(items);
                return items;
            })
        );
    }

    delete(data: Currency): Observable<boolean> {
        console.log(data);
        return this.items$.pipe(
            take(1),
            switchMap(items => this._httpClient.delete('currencies', data.id).pipe(
                map(() => {
                    if (items) {
                        const index = items['hydra:member'].findIndex(i => i.id === data.id);
                        items['hydra:member'].splice(index, 1);
                        this._items.next(items);
                    }
                    return true;
                }),
            )),
        );
    }
}