import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { HelperService } from 'app/core/services/helper.service';
import { UserService } from 'app/core/user/user.service';
import { ROUTES } from 'app/layout/layouts/navigation/navigation.items';
import { of, switchMap } from 'rxjs';

export const RolesGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const helper: HelperService = inject(HelperService);

    // Check the authentication status
    return inject(UserService).user$.pipe(
        switchMap((user) => {
            // If the user is not authenticated...
            if (!user) {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

                return of(urlTree);
            }

            const requiredRoles = helper.getRolesByLink(state.url, ROUTES);
            const userRoles = user.roles;

            if(requiredRoles) {
                return of(userRoles.some(role => requiredRoles.includes(role)));
            }
            return of(true);            
        }),
    );
};
