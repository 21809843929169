import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Pagination } from "app/core/interfaces/pagination";
import { User } from "app/core/interfaces/user";
import { RestApiClientService } from "app/core/services/rest-api-client.service";
import { BehaviorSubject, Observable, filter, map, switchMap, take, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UsersService {
    // Private
    private _users: BehaviorSubject<User[] | null> = new BehaviorSubject(null);
    private _user: BehaviorSubject<User | null> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: RestApiClientService) {
    }


    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get users$(): Observable<User[]> {
        return this._users.asObservable();
    }

    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }


    getUsers(params?: any): Observable<User[]> {
        return this._httpClient.get<User[]>('users', params).pipe(
            tap((users) => {
                this._users.next(users);
                const length = users['hydra:totalItems'];
                const page = (!params.page || params.page <1) ? 0 : params.page - 1 ;
                const size = params.itemsPerPage ?? 10;
                const startIndex = page * size;
                const end = Math.min((size * (page+1)), length);
                const endIndex = end-1;
                const lastPage = Math.max(Math.ceil(length / size), 1);
                
                const pagination: Pagination = {
                    page,
                    size,
                    startIndex,
                    endIndex,
                    length,
                    lastPage

                };
                this._pagination.next(pagination);
            }),
        );
    }

    getById(id: number): Observable<User> {
        return this._httpClient.get<User>('users/' + id).pipe(
            tap((user) => {
                this._user.next(user);
                return user;
            }),
        );
    }
    

    createNewUser(): Observable<User> {
        return this._users.pipe(
            take(1),
            map((users) => {
                const newUser = {};
                users['hydra:member'].unshift(newUser);
                this._users.next(users);
                return newUser;
            })
        );
    }

    cancelCreation(): Observable<User[]> {
        return this._users.pipe(
            take(1),
            map((users) => {
                users['hydra:member'].splice(0, 1);
                this._users.next(users);
                return users;
            })
        );
    }

    create(data: User): Observable<User> {
        return this.users$.pipe(
            take(1),
            switchMap(users => this._httpClient.post<User>('users', data).pipe(
                map((newUser) => {
                    users['hydra:member'].unshift(newUser);
                    this._users.next(users);
                    return newUser;
                })
            ))
        )
    }

    update(data: User): Observable<User> {
        return this.users$.pipe(
            take(1),
            switchMap(users => this._httpClient.put<User>('users', data.id, data).pipe(
                map((updatedUser) => {
                    const index = users['hydra:member'].findIndex(item => item.id == data.id);
                    users['hydra:member'][index] = updatedUser;
                    this._users.next(users);
                    return updatedUser;
                }),
                switchMap(updatedUser => this.user$.pipe(
                    take(1),
                    filter(item => item && item.id === data.id),
                    tap(() => {
                        this._user.next(updatedUser);
                        return updatedUser;
                    })
                ))
            ))
        )
    }


    delete(data: User): Observable<boolean> {
        return this.users$.pipe(
            take(1),
            switchMap(users => this._httpClient.delete('users', data.id).pipe(
                map(() => {
                    const index = users['hydra:member'].findIndex(item => item.id === data.id);
                    users['hydra:member'].splice(index, 1);
                    this._users.next(users);
                    return true;
                }),
            )),
        );
    }
}